<template>
  <nav class="navbar navbar-light bg-light p-0 mb-3" role="navigation">
    <div class="d-flex align-items-center p-2 w-100">
      <div class="mr-auto">
        <a class="sidebar-toggle d-flex mx-2" @click="toggleSidebar">
          <i class="hamburger align-self-center"></i>
        </a>
      </div>
      <div class="mr-2">
        <button class="btn btn-primary p-2" @click="viewCart">
          <CartTimer :isAdmin="true" />
        </button>
      </div>
      <div class="dropdown">
        <button
          class="btn btn-primary dropdown-toggle p-2 profile-button"
          type="button"
          id="accountMenu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span v-if="userFirstName == 'LOADING...'">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span v-else>
            {{
              userFirstName
                ? userFirstName.toUpperCase()
                : username
                ? username.toUpperCase()
                : "USER"
            }}
          </span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="accountMenu"
        >
          <a
            class="dropdown-item"
            @click="$router.push('/admin/user-profile').catch(() => {})"
            >PROFILE</a
          >
          <a class="dropdown-item" @click="onlineHelpClicked" target="_blank"
            >ONLINE HELP</a
          >
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" type="button" @click="logOut">
            SIGN OUT
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import CartTimer from "@/components/layout/CartTimer.vue";

export default {
  name: "TheAdminHeader",
  props: {
    userFirstName: String,
    username: String,
    signedIn: Boolean
  },
  components: {
    CartTimer
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    }
  },
  methods: {
    viewCart() {
      this.$router.push("/admin/shopping-cart").catch(() => {});
    },
    async logOut() {
      try {
        this.$store.dispatch("auth/logOut");
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    toggleSidebar() {
      var sidebar = document.getElementById("sidebar");
      sidebar.classList.toggle("collapsed");
    },
    async onlineHelpClicked() {
      let domainName = "";
      let hostname = window.location.hostname;
      let hostNameParts = hostname.split(".");
      if (hostNameParts.length == 1) {
        domainName = "." + hostNameParts[0];
      } else {
        domainName =
          "." +
          hostNameParts[hostNameParts.length - 2] +
          "." +
          hostNameParts[hostNameParts.length - 1];
      }
      document.cookie =
        "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=" +
        domainName +
        "path=/;";
      const token = await this.$store.dispatch("auth/getAuthToken");
      document.cookie =
        "authToken=" + token + ";domain=" + domainName + ";path=/";
      window.open(this.tenant.adminOnlineHelpUrl, "_blank");
    }
  }
};
</script>

<style scoped></style>
