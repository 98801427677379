<template>
  <div>
    <div class="non-prod text-center py-1" v-if="nonProd == true">
      this is a non-prod environment
    </div>
    <div class="wrapper" v-if="verifyStatus === 'verified'">
      <TheAdminSidebar />
      <div class="main d-flex flex-column">
        <LogoutTimer />
        <TheAdminHeader
          :tenant="tenant"
          :username="username"
          :signedIn="signedIn"
          :userFirstName="userFirstName"
        />
        <main id="main-content" class="content pt-1 flex-grow-1">
          <Alert
            v-if="alert && alert.layer === 'admin'"
            :alert="alert"
            class="mb-2"
          />
          <router-view />
        </main>
        <TheAdminFooter :tenant="tenant" />
      </div>
    </div>
  </div>
</template>

<script>
import TheAdminHeader from "@/components/admin/layout/TheAdminHeader.vue";
import TheAdminFooter from "@/components/admin/layout/TheAdminFooter.vue";
import TheAdminSidebar from "@/components/admin/layout/TheAdminSidebar.vue";
import Alert from "@/components/alert/Alert.vue";
import LogoutTimer from "@/components/layout/LogoutTimer.vue";

export default {
  name: "AdminApp",
  title: "Admin - Home",
  components: {
    TheAdminHeader,
    TheAdminSidebar,
    TheAdminFooter,
    Alert,
    LogoutTimer
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    username() {
      return this.$store.getters["auth/username"];
    },
    userFirstName() {
      return this.$store.getters["auth/userFirstName"];
    },
    signedIn() {
      return this.$store.getters["auth/signedIn"];
    },
    verifyStatus() {
      return this.$store.getters["auth/verifyStatus"];
    },
    alert: {
      cache: false,
      get() {
        return this.$store.getters["alert/errorAlert"];
      }
    },
    nonProd() {
      return (
        process.env.VUE_APP_ENV_FLAG.toLowerCase() != "prod" &&
        process.env.VUE_APP_ENV_FLAG.toLowerCase() != "production"
      );
    }
  }
};
</script>

<style scoped>
.content {
  flex: 1 0 auto;
}
.non-prod {
  color: white;
  font-weight: bold;
  background: #d90d21;
  text-transform: uppercase;
}
</style>
