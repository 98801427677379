<template>
  <div class="alert alert-dismissible mb-0" role="alert" :class="alert.type">
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click="clearAlert"
    >
      <span aria-hidden="true">×</span>
    </button>
    <div class="alert-icon">
      <i class="far fa-fw fa-bell"></i>
    </div>
    <div class="alert-message" v-if="!alert.htmlMessage">
      {{ alert.message }}
    </div>
    <div class="alert-message" v-else v-html="alert.htmlMessage"></div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    alert: Object
  },
  methods: {
    clearAlert() {
      this.$store.dispatch("alert/clear");
    }
  }
};
</script>
