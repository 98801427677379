<template>
  <div>
    <b-modal
      ref="logout-modal"
      size="sm"
      hide-header-close
      header-class="border-0"
      footer-class="border-0"
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <h4>You will be logged out in 2 minutes.</h4>
        <h4>Click OK to stay logged in.</h4>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn ok-button" @click="ok()">OK</b-button>
        <b-button class="btn logout-button" @click="logout">Log Out</b-button>
      </template>
    </b-modal>
    <b-modal
      ref="loggedout-modal"
      size="sm"
      hide-header-close
      header-class="border-0"
      footer-class="border-0"
    >
      <div class="d-block text-center">
        <h4>You have been logged out due to inactivity.</h4>
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button class="btn ok-button" @click="ok()">OK</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "LogoutTimer",
  data() {
    return {
      startDate: null,
      minutesUntilLogout: 2
    };
  },
  methods: {
    showModal() {
      this.$refs["logout-modal"].show();
    },
    hideModal() {
      this.$refs["logout-modal"].hide();
    },
    startTimer() {
      if (!this.signedIn) return;

      this.showModal();
      var self = this;
      let time = setInterval(function() {
        if (!self.isAppIdle) {
          clearInterval(time);
        }
        const endDate = moment(self.startDate)
          .add(self.minutesUntilLogout, "m")
          .toDate();
        const timerEnd = new Date(endDate).getTime();
        const now = new Date().getTime();
        const distance = timerEnd - now;
        if (distance < 0) {
          clearInterval(time);
          self.logout();
          self.$refs["loggedout-modal"].show();
        }
      }, 1000);
    },
    logout() {
      this.hideModal();
      this.$store.dispatch("auth/logOut");
    }
  },
  computed: {
    signedIn() {
      return this.$store.getters["auth/signedIn"];
    }
  },
  onIdle() {
    this.startDate = new Date();
    this.startTimer();
  }
};
</script>

<style scoped>
.logout-button {
  background-color: #eeeeee !important;
  border: #eeeeee !important;
  color: black;
}
.ok-button {
  background-color: #1078a8 !important;
  border: #1078a8 !important;
  color: white;
}</style
>>
