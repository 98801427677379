<template>
  <nav id="sidebar" class="sidebar no-print">
    <div class="sidebar-content">
      <router-link
        class="sidebar-brand"
        to="/admin"
        @click.native="logoClicked"
      >
        <img
          src="@/assets/images/logos/DNR-IL-FinalColors.png"
          height="60"
          alt="Illinois Department of Natural Resources"
        />
      </router-link>

      <ul class="sidebar-nav">
        <li class="sidebar-item">
          <router-link
            id="linkCustomerSearch"
            class="sidebar-link"
            to="/admin/customer-search"
            @click.native="customerSearch"
          >
            <i class="fas fa-fw fa-users align-middle"></i>
            <span class="align-middle">Customer Management</span></router-link
          >
        </li>
        <li class="sidebar-item" v-if="showParkManagement">
          <a
            href="#parkManagement"
            data-toggle="collapse"
            class="sidebar-link sidebar-dropdown-link collapsed"
          >
            <i class="fas fa-fw fa-tree align-middle"></i>
            <span class="align-middle">Park Management</span>
          </a>
          <ul
            id="parkManagement"
            class="sidebar-dropdown list-unstyled collapse"
            data-parent="#sidebar"
          >
            <li class="sidebar-item" v-if="showParkAlerts">
              <router-link
                id="linkParkAlerts"
                class="sidebar-link"
                to="/admin/park-alert-search"
                @click.native="activateSideBarLink('linkParkAlerts')"
                >Park Alerts</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showParkClosure">
              <router-link
                id="linkParkClosure"
                class="sidebar-link"
                to="/admin/park-closure-search"
                @click.native="activateSideBarLink('linkParkClosure')"
                >Park Closure</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showParkMap">
              <router-link
                id="linkParkMap"
                class="sidebar-link"
                to="/admin/park-map"
                @click.native="activateSideBarLink('linkParkMap')"
                >Park Map</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showParkSearch">
              <router-link
                id="linkParkSearch"
                class="sidebar-link"
                to="/admin/park-search"
                @click.native="activateSideBarLink('linkParkSearch')"
                >Park Search</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showSpotSearch">
              <router-link
                id="linkSpotSearch"
                class="sidebar-link"
                to="/admin/spot-search"
                @click.native="activateSideBarLink('linkSpotSearch')"
                >Park Spot Search</router-link
              >
            </li>
          </ul>
        </li>
        <li class="sidebar-item" v-if="showReportManagement">
          <router-link
            id="linkReporting"
            class="sidebar-link"
            to="/admin/reporting"
            @click.native="activateSideBarLink('linkReporting')"
          >
            <i class="fas fa-fw fa-file-alt align-middle"></i>
            <span class="align-middle">Report Management</span>
          </router-link>
        </li>
        <li class="sidebar-item" v-if="showReservationManagement">
          <a
            href="#reservationManagment"
            data-toggle="collapse"
            class="sidebar-link sidebar-dropdown-link collapsed"
          >
            <i class="fas fa-fw fa-map-marked-alt align-middle"></i>
            <span class="align-middle">Reservation Management</span>
          </a>
          <ul
            id="reservationManagment"
            class="sidebar-dropdown list-unstyled collapse"
            data-parent="#sidebar"
          >
            <li class="sidebar-item" v-if="showAddReservation">
              <router-link
                id="linkReservationAdd"
                class="sidebar-link"
                to="/admin/reservation-add"
                @click.native="addReservation()"
                >Reservation Add</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showReservationSearch">
              <router-link
                id="linkReservationSearch"
                class="sidebar-link"
                to="/admin/reservation-search"
                @click.native="reservationSearch()"
                >Reservation Search</router-link
              >
            </li>
            <li class="sidebar-item" v-if="showPOSReservations">
              <router-link
                id="linkPosReservations"
                class="sidebar-link"
                to="/admin/pos-reservations"
                @click.native="activateSideBarLink('linkPosReservations')"
                >POS Reservations</router-link
              >
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <router-link
            id="linkUserSearch"
            class="sidebar-link"
            to="/admin/user-search"
            @click.native="userSearch"
          >
            <i class="fas fa-fw fa-users align-middle"></i>
            <span class="align-middle">User Management</span></router-link
          >
        </li>
        <li class="sidebar-item" v-if="showSystemSettings">
          <a
            href="#systemSettings"
            data-toggle="collapse"
            class="sidebar-link sidebar-dropdown-link collapsed"
          >
            <i class="fas fa-fw fa-cog align-middle"></i>
            <span class="align-middle">System Settings</span>
          </a>
          <ul
            id="systemSettings"
            class="sidebar-dropdown list-unstyled collapse"
            data-parent="#sidebar"
          >
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsActivitesAmenities')"
            >
              <router-link
                id="linkActivities"
                class="sidebar-link"
                to="/admin/points-of-interest-management"
                @click.native="activateSideBarLink('linkActivities')"
                >Activities & Amenities (POI)</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsAttributes')"
            >
              <router-link
                id="linkAttributes"
                class="sidebar-link"
                to="/admin/attribute-management"
                @click.native="activateSideBarLink('linkAttributes')"
                >Attributes</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsBusinessRules')"
            >
              <a
                href="#businessRuleManagement"
                data-toggle="collapse"
                class="sidebar-link sidebar-dropdown-link collapsed"
              >
                <span class="align-middle">Business Rules</span>
              </a>
              <ul
                id="businessRuleManagement"
                class="sidebar-dropdown list-unstyled collapse"
                data-parent="#systemSettings"
              >
                <li class="sidebar-item">
                  <router-link
                    id="linkAgencyWalkin"
                    class="sidebar-link"
                    to="/admin/brm/agency-walkin-period"
                    @click.native="activateSideBarLink('linkAgencyWalkin')"
                    >Agency Walk-In Period</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMaxArrival"
                    class="sidebar-link"
                    to="/admin/brm/maximum-arrival-date"
                    @click.native="activateSideBarLink('linkMaxArrival')"
                    >Maximum Arrival Date</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMinArrival"
                    class="sidebar-link"
                    to="/admin/brm/minimum-arrival-date"
                    @click.native="activateSideBarLink('linkMinArrival')"
                    >Minimum Arrival Date</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMaxStay"
                    class="sidebar-link"
                    to="/admin/brm/maximum-stay"
                    @click.native="activateSideBarLink('linkMaxStay')"
                    >Maximum Stay</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMinStay"
                    class="sidebar-link"
                    to="/admin/brm/minimum-stay"
                    @click.native="activateSideBarLink('linkMinStay')"
                    >Minimum Stay</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMaxRes"
                    class="sidebar-link"
                    to="/admin/brm/maximum-active-reservations-on-account"
                    @click.native="activateSideBarLink('linkMaxRes')"
                    >Maximum Active Reservations On Account</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMaxResPer"
                    class="sidebar-link"
                    to="/admin/brm/maximum-reservations-per-transaction"
                    @click.native="activateSideBarLink('linkMaxResPer')"
                    >Maximum Reservations Per Transaction</router-link
                  >
                </li>
                <li class="sidebar-item">
                  <router-link
                    id="linkMaxResDate"
                    class="sidebar-link"
                    to="/admin/brm/maximum-reservations-same-start-date"
                    @click.native="activateSideBarLink('linkMaxResDate')"
                    >Maximum Reservations With Same Start Date</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsCommunicationTemplates')"
            >
              <router-link
                id="linkCom"
                class="sidebar-link"
                to="/admin/communication-template-management"
                @click.native="activateSideBarLink('linkCom')"
                >Communication Templates</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsDateManagement')"
            >
              <router-link
                id="linkDate"
                class="sidebar-link"
                to="/admin/date-block-management"
                @click.native="activateSideBarLink('linkDate')"
                >Date Management</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsDiscountClass')"
            >
              <router-link
                id="linkDiscountClass"
                class="sidebar-link"
                to="/admin/discount-class-management"
                @click.native="activateSideBarLink('linkDiscountClass')"
                >Discount Class</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsOperationalSeasons')"
            >
              <router-link
                id="linkOperational"
                class="sidebar-link"
                to="/admin/operational-season-management"
                @click.native="activateSideBarLink('linkOperational')"
                >Operational Seasons</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsPricingSeasons')"
            >
              <router-link
                id="linkPricingSeason"
                class="sidebar-link"
                to="/admin/pricing-seasons"
                @click.native="activateSideBarLink('linkPricingSeason')"
                >Pricing Seasons</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsProductClasses')"
            >
              <router-link
                id="linkProductClass"
                class="sidebar-link"
                to="/admin/product-class-management"
                @click.native="activateSideBarLink('linkProductClass')"
                >Product Classes</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsProductTypes')"
            >
              <router-link
                id="linkProductType"
                class="sidebar-link"
                to="/admin/product-type-management"
                @click.native="activateSideBarLink('linkProductType')"
                >Product Types</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsSpotTypes')"
            >
              <router-link
                id="linkSpotTypes"
                class="sidebar-link"
                to="/admin/spot-type-management"
                @click.native="activateSideBarLink('linkSpotTypes')"
                >Spot Types</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsTenantSettings')"
            >
              <router-link
                id="linkTenantSettings"
                class="sidebar-link"
                to="/admin/tenant-settings"
                @click.native="activateSideBarLink('linkTenantSettings')"
                >Tenant Settings</router-link
              >
            </li>
            <li
              class="sidebar-item"
              v-if="checkPermission('SystemSettingsPermissions')"
            >
              <router-link
                id="linkUserRole"
                class="sidebar-link"
                to="/admin/user-role-management"
                @click.native="activateSideBarLink('linkUserRole')"
                >User Role Management</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "TheAdminSidebar",
  mixins: [checkPermissionMixin],
  props: {
    userFirstName: String,
    username: String,
    signedIn: Boolean
  },
  methods: {
    logoClicked() {
      document
        .getElementsByClassName("sidebar-link-active")
        .forEach(element => {
          element.className = "sidebar-link";
        });
      document
        .getElementsByClassName("sidebar-dropdown-link")
        .forEach(element => {
          element.className = "sidebar-link sidebar-dropdown-link collapsed";
          element.setAttribute("aria-expanded", false);
        });
      document.getElementsByClassName("sidebar-dropdown").forEach(element => {
        element.className = element.className.replace(" show", "");
      });
    },
    activateSideBarLink(id) {
      document
        .getElementsByClassName("sidebar-link-active")
        .forEach(element => {
          element.className = "sidebar-link";
        });
      document.getElementById(id).className += " sidebar-link-active";
    },
    addReservation() {
      this.$store.dispatch("transaction/clear");
      this.$store.dispatch("search/clear");
      this.activateSideBarLink("linkReservationAdd");
    },
    reservationSearch() {
      this.$store.dispatch("search/clear");
      this.$store.dispatch("admin/clearReservationSearch");
      this.activateSideBarLink("linkReservationSearch");
    },
    userSearch() {
      this.$store.dispatch("admin/clearUserSearch");
      this.activateSideBarLink("linkUserSearch");
    },
    customerSearch() {
      this.$store.dispatch("admin/clearCustomerSearch");
      this.$store.dispatch("admin/clearReservationSearch");
      this.activateSideBarLink('linkCustomerSearch');
    }
  },
  computed: {
    showAddReservation() {
      return this.checkPermission("ReservationManagementAdd");
    },
    showPOSReservations() {
      return this.checkPermission("ReservationManagementPointOfSaleManagement");
    },
    showReservationSearch() {
      return this.checkPermission("ReservationManagementView");
    },
    showReservationManagement() {
      return this.showAddReservation || this.showReservationSearch;
    },
    showParkAlerts() {
      return (
        this.checkPermission("ParkManagementParkAlertsGlobal") ||
        this.checkPermission("ParkManagementParkAlertsParkSpot")
      );
    },
    showParkClosure() {
      return this.checkPermission("ParkManagementParkClosure");
    },
    showParkMap() {
      return this.checkPermission("ParkManagementParkMap");
    },
    showParkSearch() {
      return this.checkPermission("ParkManagementParkSearch");
    },
    showSpotSearch() {
      return this.checkPermission("ParkManagementSpotSearch");
    },
    showReportManagement() {
      return this.checkPermission("ReportManagementReportManagement");
    },
    showParkManagement() {
      return (
        this.showParkAlerts ||
        this.showParkClosure ||
        this.showParkMap ||
        this.showParkSearch ||
        this.showSpotSearch
      );
    },
    showSystemSettings() {
      return (
        this.checkPermission("SystemSettingsActivitesAmenities") ||
        this.checkPermission("SystemSettingsAttributes") ||
        this.checkPermission("SystemSettingsBusinessRules") ||
        this.checkPermission("SystemSettingsCommunicationTemplates") ||
        this.checkPermission("SystemSettingsDateManagement") ||
        this.checkPermission("SystemSettingsDiscountClass") ||
        this.checkPermission("SystemSettingsOperationalSeasons") ||
        this.checkPermission("SystemSettingsPermissions") ||
        this.checkPermission("SystemSettingsPricingSeasons") ||
        this.checkPermission("SystemSettingsProductClasses") ||
        this.checkPermission("SystemSettingsProductTypes") ||
        this.checkPermission("SystemSettingsSpotTypes") ||
        this.checkPermission("SystemSettingsTenantSettings")
      );
    }
  }
};
</script>
<style scoped>
i {
  opacity: 0.5 !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .card {
    width: 100% !important;
    display: block !important;
  }
  .page {
    page-break-after: always !important;
  }
}
</style>
