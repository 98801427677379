<template>
  <nav class="navbar" role="navigation" aria-label="footer">
    <div class="container mt-2 justify-content-center">
      <div class="row justify-content-center">
        <div class="col col-lg-4 col-md-12 col-sm-12 col-12">
          <h2 class="text-center text-white m-0">CONNECT WITH US</h2>
          <p class="text-center my-3">
            <a
              v-if="tenant.facebookUrl"
              class="text-white mx-2"
              :href="tenant.facebookUrl"
              target="_blank"
            >
              <span class="sr-only">Facebook</span>
              <i class="align-middle fab fa-fw fa-facebook-square"></i>
            </a>
            <a
              v-if="tenant.instagramUrl"
              class="text-white mx-2"
              :href="tenant.instagramUrl"
              target="_blank"
            >
              <span class="sr-only">Instagram</span>
              <i class="align-middle fab fa-fw fa-instagram"></i>
            </a>
            <a
              v-if="tenant.twitterUrl"
              class="text-white mx-2"
              :href="tenant.twitterUrl"
              target="_blank"
            >
              <span class="sr-only">Twitter</span>
              <i class="align-middle fab fa-fw fa-twitter-square"></i>
            </a>
            <a
              v-if="tenant.pinterestUrl"
              class="text-white mx-2"
              :href="tenant.pinterestUrl"
              target="_blank"
            >
              <span class="sr-only">Pinterest</span>
              <i class="align-middle fab fa-fw fa-pinterest-square"></i>
            </a>
          </p>
          <p class="text-center">
            <a
              v-if="tenant.supportInfo.email"
              :href="`mailto:${tenant.supportInfo.email}`"
              class="text-white mx-2"
              >Contact Support</a
            >
            <a href="javascript:void(0);" class="text-white mx-2">My Account</a>
            <a
              v-if="tenant.supportInfo.phoneNumber"
              :href="'tel:' + tenant.supportInfo.phoneNumber"
              class="text-white mx-2"
              >Contact Support:
              {{ tenant.supportInfo.phoneNumber | formatPhoneNumber }}</a
            >
          </p>
        </div>
        <div
          v-if="tenant.policies && tenant.policies.length > 0"
          class="col col-lg-2 col-md-6 col-sm-6 col-6"
        >
          <p
            v-for="policy in tenant.policies"
            :key="policy.id"
            class="text-center m-0"
          >
            <a class="text-white" :href="policy.url" target="_blank">{{
              getLinkName(policy)
            }}</a>
          </p>
        </div>
        <div class="col col-lg-2 col-md-6 col-sm-6 col-6">
          <div v-if="tenant.externalLinks && tenant.externalLinks.length > 0">
            <p
              v-for="link in tenant.externalLinks"
              :key="link.id"
              class="text-center m-0"
            >
              <a class="text-white" :href="link.url" target="_blank">{{
                getLinkName(link)
              }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <img
            src="@/assets/images/logos/OneOutdoorLogo_2020_white.png"
            alt="OneOutdoor Logo"
            style="height: 45px; display: block; margin: auto"
          />
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <p class="text-center text-white m-0">
            &copy; {{ new Date().getFullYear() }} NIC, Inc. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheAdminFooter",
  props: {
    tenant: Object
  },
  methods: {
    getLinkName(link) {
      if (link.url.endsWith(".pdf")) {
        return link.name + " (PDF)";
      } else {
        return link.name;
      }
    }
  }
};
</script>

<style scoped>
.row {
  width: 100%;
}
hr {
  border-top: 1px solid white !important;
}
i {
  font-size: 2rem !important;
}
.navbar {
  background: #333333;
  background: -moz-linear-gradient(top, #333333 1%, #000000 100%);
  background: -webkit-linear-gradient(top, #333333 1%, #000000 100%);
  background: linear-gradient(to bottom, #333333 1%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333333', endColorstr='#000000',GradientType=0 );
}
h2 {
  font-size: large;
}
</style>
