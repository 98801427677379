<template>
  <div>
    <div>
      {{ timeRemaining != "" ? timeRemaining : "CART" }}
      <i class="align-middle pb-1 fas fa-fw fa-shopping-cart"></i>
      <span class="count" v-if="numberOfItemsInCart > 0">{{
        numberOfItemsInCart
      }}</span>
    </div>
    <div>
      <b-modal
        id="extendTimeModal"
        title="Your cart is about to expire."
        no-stacking
        no-close-on-backdrop
      >
        <p class="my-4">
          This is a friendly reminder that your cart will expire in
          {{ timeRemaining }}. Once time is up, all items will be removed from
          your cart.
        </p>
        <p>
          We can provide more time to finish this reservation, though! Would
          five more minutes be helpful?
        </p>
        <template v-slot:modal-footer="{ ok }">
          <b-button class="btn" variant="primary" @click="extendTimeout"
            >Add 5 Minutes</b-button
          >
          <b-button class="btn" variant="secondary" @click="negative(ok)"
            >Ignore</b-button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/CartService.js";
import AdminCartService from "@/services/admin/AdminCartService.js";

export default {
  name: "CartTimer",
  data() {
    return {
      timeRemaining: "",
      timeInterval: null
    };
  },
  props: {
    isAdmin: { type: Boolean, default: false }
  },
  methods: {
    getCartService() {
      if (this.isAdmin) {
        return new AdminCartService(this.tenantId);
      }
      return new CartService(this.tenantId);
    },
    extendTimeout() {
      this.reservationInProgressCheck();
      const cartService = this.getCartService();
      cartService.extendUserCartTimeout().then(response => {
        if (response.statusCode == "Success") {
          //Set new timeout
          this.setLockedUntilDate(response.data.lockedUntil);
        }
      });
      this.$bvModal.hide("extendTimeModal");
    },
    negative(ok) {
      this.reservationInProgressCheck();
      ok();
    },
    reservationInProgressCheck() {
      if (this.timeRemaining === "") {
        if (this.isAdmin) {
          this.$router.push("/admin").catch(() => {});
        } else {
          this.$router.push("/").catch(() => {});
        }
      }
    },
    setTimerDisplay() {
      var self = this;
      const timerEnd = new Date(self.lockedUntil).getTime();
      const now = new Date().getTime();
      const distance = timerEnd - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(self.timeInterval);
        self.timeRemaining = "";
        this.$store.dispatch("transaction/clear");
        const cartService = this.getCartService();
        cartService.clearUserCart();
      } else {
        self.timeRemaining =
          (minutes < 1 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds);
        if (minutes <= 5 && seconds == 0) {
          if (!self.extendTimePopupShown) {
            self.$store.commit("transaction/setExtendTimePopupShown", true);

            //show popup
            self.$bvModal.show("extendTimeModal");
          }
        }
      }
    },
    startTimer() {
      clearInterval(this.timeInterval);
      if (!this.lockedUntil) {
        this.timeRemaining = "";
        return;
      }
      this.setTimerDisplay();
      var self = this;
      this.timeInterval = setInterval(self.setTimerDisplay, 1000);
    },
    setLockedUntilDate(lockedUntil) {
      this.$store.commit("transaction/setCartExpiresDate", lockedUntil);
    }
  },
  computed: {
    lockedUntil() {
      return this.$store.getters["transaction/cartExpiresDate"];
    },
    numberOfItemsInCart() {
      return this.$store.getters["transaction/numberOfItemsInCart"];
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    extendTimePopupShown() {
      return this.$store.getters["transaction/extendTimePopupShown"];
    }
  },
  watch: {
    lockedUntil() {
      this.startTimer();
    },
    deep: true
  },
  created() {
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  }
};
</script>
<style scoped>
.count {
  background: red;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  height: 18px;
  width: 18px;
  padding: 1px;
  position: absolute;
  top: 20px;
  right: 10px;
  text-align: center;
  transition: top 0.1s ease-out;
  font-size: 0.675rem;
  color: #fff;
}
</style>
